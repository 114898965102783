import esApiV3 from './esapiv3.service';
import exception from './exception.service';

const api = {
  vendorById: (id) => `/vendors/${id}`,
  vendorCustomers: (vendorId) => `vendors/${vendorId}/customers`,
  rosterView: () => 'vendors/rosterView',
  vendorAssigned: () => '/vendors/self',
  suggestionsByEquipmentId: (id) =>
    `/vendor-preferences/suggestions/asset/${id}`,
  categoryRostersForLocation: (locationId) =>
    `vendor-preferences/locations/${locationId}/servicecategories`,
  assetRostersForLocation: (locationId) =>
    `vendor-preferences/locations/${locationId}/assets`,
  categoryRosterById: (locationId, categoryId) =>
    `vendor-preferences/locations/${locationId}/servicecategories/${categoryId}`,
  locationRoster: (locationId) => `vendor-preferences/locations/${locationId}`,
  customerPreferences: (customerId) =>
    `vendor-preferences/customers/${customerId}`,
  addDoNotSuggestVendorForLocation: (locationId) =>
    `vendor-preferences/locations/${locationId}/doNotSuggest`,
  lookup: () => 'vendors/lookup',
  lookupDetails: (placeId) => `vendors/lookup/${placeId}`,
  vendor: () => 'vendors',
  search: () => 'vendors/search',
  ssoHistoryView: () => 'vendors/ssoHistoryView',
  eightySixVendors: () => 'vendors/eightySixVendors',
  getInternalTechs: () => 'vendors/internalTechnicianVendors',
};

export async function getSuggestionsByEquipmentId(id) {
  try {
    const res = await esApiV3.get(api.suggestionsByEquipmentId(id));
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
}

export async function getVendorForUser(showError) {
  if (typeof showError === 'undefined') showError = true;
  try {
    const res = await esApiV3.get(api.vendorAssigned());
    return res.data;
  } catch (ex) {
    if (showError) exception.showCustomError(ex);
  }
}

export async function getCustomersForVendor(vendorId) {
  try {
    const res = await esApiV3.get(api.vendorCustomers(vendorId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
}

export async function patchVendorById(id, data) {
  try {
    const res = await esApiV3.patch(api.vendorById(id), data);
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
}

export async function getVendorById(id, query) {
  try {
    const res = await esApiV3.get(api.vendorById(id), { params: query });
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
}

export async function getVendorsForRosterViewByQuery(query) {
  return esApiV3
    .get(api.rosterView(), { params: query })
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(
        ex,
        'An error occurred while trying to get vendors for roster view.',
      );
    });
}

export async function getCategoryRostersForLocation(locationId) {
  try {
    const res = await esApiV3.get(api.categoryRostersForLocation(locationId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
}

export async function getAssetRostersForLocation(locationId) {
  try {
    const res = await esApiV3.get(api.assetRostersForLocation(locationId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
}

export async function saveCategoryRoster(
  locationId,
  categoryId,
  body,
  exclusions,
) {
  try {
    const res = await esApiV3.put(
      api.categoryRosterById(locationId, categoryId),
      {
        [exclusions ? 'doNotSuggest' : 'roster']: body,
      },
    );
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
}

export async function getLocationRoster(locationId) {
  try {
    const res = await esApiV3.get(api.locationRoster(locationId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
}

export async function getCustomerPreferences(customerId) {
  try {
    const res = await esApiV3.get(api.customerPreferences(customerId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
}

export async function saveLocationRoster(locationId, body, exclusions) {
  try {
    const res = await esApiV3.put(api.locationRoster(locationId), {
      [exclusions ? 'doNotSuggest' : 'roster']: body,
    });
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
}

export async function saveDoNotSuggestVendorForLocation(locationId, body) {
  try {
    const res = await esApiV3.post(
      api.addDoNotSuggestVendorForLocation(locationId),
      body,
    );
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
}

export async function getVendorsLookup(searchString) {
  try {
    const res = await esApiV3.get(api.lookup(), {
      params: { text: searchString },
    });
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
}

export async function getVendorLookupDetails(placeId) {
  try {
    const res = await esApiV3.get(api.lookupDetails(placeId));
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
}

export async function createVendor(body) {
  try {
    const res = await esApiV3.post(api.vendor(), body);
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
}

/**
 *
 * @param {string} searchText
 * @returns {Promise<any>}
 */
export async function searchVendors(searchText) {
  try {
    const res = await esApiV3.get(api.search(), { params: { searchText } });
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
}

export async function getVendorsForHistoryView({
  locationId,
  searchText,
  startDate,
  endDate,
}) {
  try {
    const res = await esApiV3.get(api.ssoHistoryView(), {
      params: { locationId, searchText, startDate, endDate },
    });
    return res.data;
  } catch (ex) {
    exception.showCustomError(ex);
  }
}

export async function getInternalTechVendors() {
  return esApiV3
    .get(api.getInternalTechs())
    .then((result) => result.data)
    .catch((ex) => {
      exception.showCustomError(
        ex,
        'An error occurred while trying to get vendors for roster view.',
      );
    });
}

export async function getEightySixVendors({ limit, offset, ...query }) {
  try {
    const res = await esApiV3.get(api.eightySixVendors(), {
      params: { limit, offset, ...query },
    });
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get eighty six vendors',
    );
  }
}
