import userProfileService from '@/service/user-profile.service';
import {
  createAsset,
  getAssetPickerViewMagicLinkIntake,
  getAssetTypes,
  getManufacturers,
  getServiceCategories,
  getViewableLocations,
} from '@/service/request-intake-magic-link.service';

const module = {
  namespaced: true,
};

const newRequestObject = {
  location: null,
  contact: null,
  priority: 'Low',
  asset: null,
  vendor: null,
  incidentDescription: null,
};

module.state = {
  viewableLocations: [],
  showRequestDialog: false,
  showEquipmentDialog: false,
  newRequest: { ...newRequestObject },
  contactsForLocation: [],
};

module.mutations = {
  SET_SHOW_EQUIPMENT_DIALOG(state, showDialog) {
    state.showEquipmentDialog = showDialog;
  },
  SET_LOCATIONS(state, locations) {
    state.locations = locations;
  },
  RESET_NEW_REQUEST(state) {
    state.newRequest = { ...newRequestObject };
  },
  SET_NEW_REQUEST_LOCATION(state, location) {
    state.newRequest.location = location;
  },
  SET_NEW_REQUEST_ASSET(state, asset) {
    state.newRequest.asset = asset;
  },
  SET_CONTACTS_FOR_LOCATION(state, contacts) {
    state.contactsForLocation = contacts;
  },
  SET_NEW_REQUEST_CONTACT(state, contact) {
    state.newRequest.contact = contact;
  },
  SET_NEW_CONTACT_DETAILS(state, contactDetails) {
    state.newRequest.newContactDetails = contactDetails;
  },
  SET_VIEWABLE_LOCATIONS(state, viewableLocations) {
    state.viewableLocations = viewableLocations;
  },
  SET_NEW_INCIDENT_DESCRIPTION(state, incidentDescription) {
    state.newRequest.incidentDescription = incidentDescription;
  },
  SET_NEW_REQUEST_PRIORITY(state, priority) {
    state.newRequest.requestPriority = priority;
  },
};

module.actions = {
  async toggleEquipmentDialog({ state, commit }) {
    return commit('SET_SHOW_EQUIPMENT_DIALOG', !state.showEquipmentDialog);
  },
  async resetNewRequest({ commit }) {
    return commit('RESET_NEW_REQUEST');
  },
  async setLocation({ commit }, location) {
    commit('SET_NEW_REQUEST_LOCATION', location);
  },
  async setContact({ commit }, contact) {
    commit('SET_NEW_REQUEST_CONTACT', contact);
  },
  async setNewContactDetails({ commit }, contactDetails) {
    commit('SET_NEW_CONTACT_DETAILS', contactDetails);
  },
  async setIncidentDescription({ commit }, incidentDescription) {
    commit('SET_NEW_INCIDENT_DESCRIPTION', incidentDescription);
  },
  async setPriority({ commit }, priority) {
    commit('SET_NEW_REQUEST_PRIORITY', priority);
  },

  async setAsset({ commit }, asset) {
    commit('SET_NEW_REQUEST_ASSET', asset);
    commit('SET_SHOW_EQUIPMENT_DIALOG', false);
  },
  // eslint-disable-next-line no-unused-vars
  async getAssetPickerViewMagicLinkIntake({ rootState }, locationId) {
    const assets = await getAssetPickerViewMagicLinkIntake(
      locationId,
      rootState.authSSP.token,
      rootState.authSSP.recaptcha,
    );
    return assets;
  },

  // eslint-disable-next-line no-unused-vars
  async getServiceCategoriesMagicLinkIntake({ rootState }) {
    const assets = await getServiceCategories(
      rootState.authSSP.token,
      rootState.authSSP.recaptcha,
    );
    return assets;
  },

  // eslint-disable-next-line no-unused-vars
  async getAssetTypesMagicLinkIntake({ commit, state, dispatch, rootState }) {
    const assets = await getAssetTypes(
      rootState.authSSP.token,
      rootState.authSSP.recaptcha,
    );
    return assets;
  },

  // eslint-disable-next-line no-unused-vars
  async getManufacturersMagicLinkIntake({ rootState }, params) {
    const assets = await getManufacturers(
      params,
      rootState.authSSP.token,
      rootState.authSSP.recaptcha,
    );
    return assets;
  },

  // eslint-disable-next-line no-unused-vars
  async getViewableLocations({ commit, rootState }, locationId) {
    const locations = await getViewableLocations(
      locationId,
      rootState.authSSP.token,
      rootState.authSSP.recaptcha,
    );
    commit('SET_VIEWABLE_LOCATIONS', locations);
    return locations;
  },

  // eslint-disable-next-line no-unused-vars
  async createAssetMagicLinkIntake({ rootState }, body) {
    const asset = await createAsset(
      rootState.authSSP.token,
      rootState.authSSP.recaptcha,
      body,
    );
    return asset;
  },

  async getContactsForLocation({ commit }, locationId) {
    const contacts = await userProfileService.getLocationContacts(locationId);
    contacts.locationContacts.forEach((it) => {
      it.group = 'location';
      it.label = `${it.name} (Location)`;
    });
    contacts.customerContacts.forEach((it) => {
      it.group = 'corporate';
      it.label = `${it.name} (Corporate)`;
    });
    contacts.otherLocationContacts.forEach((it) => {
      it.group = 'otherLocations';
      it.label = `${it.name} (Other Location)`;
    });

    const formattedContacts = [
      { header: 'Location' },
      ...contacts.locationContacts,
      { header: 'Corporate' },
      ...contacts.customerContacts,
      { header: 'Other Locations' },
      ...contacts.otherLocationContacts,
    ];
    return commit('SET_CONTACTS_FOR_LOCATION', formattedContacts);
  },
};

module.getters = {
  validNewContact: (state) =>
    !!(
      state.newRequest.newContactDetails &&
      state.newRequest.newContactDetails.name &&
      (state.newRequest.newContactDetails.email ||
        state.newRequest.newContactDetails.phone)
    ),

  validRequest: (state) => {
    const validNewContact = !!(
      state.newRequest.newContactDetails &&
      state.newRequest.newContactDetails.name &&
      (state.newRequest.newContactDetails.email ||
        state.newRequest.newContactDetails.phone)
    );

    return !!(
      state.newRequest.location &&
      (state.newRequest.contact || validNewContact) &&
      state.newRequest.asset &&
      (state.newRequest.incidentDescription ||
        state.newRequest.symptoms?.length > 0)
    );
  },
};

export default module;
